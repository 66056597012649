<template>
  <div>
    <NutritionMacronutrientInput
      @rawValueUpdate="$emit('macroUpdate', { type: item.prefix, value: $event })"
      v-for="(item, itemIndex) in items"
      v-model="value[item.prefix].value"
      :key="`key-${itemIndex}`"
      :nutrientLabel="item.label"
      :multiplier="item.multiplier"
    ></NutritionMacronutrientInput>
    <div class="small">
      <strong>Calories left:</strong> {{ caloriesLeft }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NutritionMacroNutrientInputGroup',
  components: {
    NutritionMacronutrientInput: () => import('@/components/nutrition/NutritionMacronutrientInput'),
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    caloriesLeft() {
      const {
        value: {
          c,
          p,
          f,
          total,
        },
      } = this;
      const cCals = c.value * 4;
      const pCals = p.value * 4;
      const fCals = f.value * 9;
      return total.value - (cCals + pCals + fCals);
    },
  },
  data() {
    return {
      items: [
        {
          prefix: 'c',
          label: 'Carbohydrate (g)',
          multiplier: 4,
        },
        {
          prefix: 'p',
          label: 'Protein (g)',
          multiplier: 4,
        },
        {
          prefix: 'f',
          label: 'Fat (g)',
          multiplier: 9,
        },
      ],
    };
  },
};
</script>
